/**
 * Footer component
 */
import React from "react"
import { Link } from "gatsby"
// import Image from "gatsby-image"
import Styled from "styled-components"

import { rhythm } from "../utils/typography"
import { colors } from "../utils/colors"

// import ContentBlock from "../components/contentBlock"

import logoSVG from '../../content/assets/Aplicaciones-paginas-web-marketing-FeedYourWeb-logo-footer.svg'
import emailSVG from '../../content/assets/icon-email.svg'
import whatsappSVG from '../../content/assets/icon-whatsapp.svg'
import twitterSVG from '../../content/assets/icon-twitter.svg'

const Container = Styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${rhythm(1)};
  background: #333;
  color: #fff;
`
const Content = Styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  padding: ${rhythm(1)};
  background: #333;
  color: #fff;
`
const Box = Styled.div`
  // width: ${rhythm(15)};
  // height: ${rhythm(15)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  padding: ${rhythm(1)};
  background: #333;
  color: #fff;
`
const Logo = Styled.img`
  width: ${rhythm(10)};
  height: auto;
`
const Item = Styled.div`
  font-size: ${rhythm(1 / 2)};
  margin: ${rhythm(1 / 8)};
`
const ItemLink = Styled(Link)`
  color: ${colors.green};
  transition: all 0.2s ease-in;
  &:hover {
    color: #fff;
  }
`
const ItemA = Styled.a`
  color: ${colors.green};
  transition: all 0.2s ease-in;
  &:hover {
    color: #fff;
  }
`
const ContactBar = Styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: ${rhythm(1)};
`
const ContactItem = Styled.a`
  margin: ${rhythm(1 / 2)};
  box-shadow: none;
  text-decoration: none;
  transition: all 0.2s ease-in;
  &:hover {
    transform: scale(1.1);
  }
`
const Icon = Styled.img`
  width: ${rhythm(1.5)};
  filter: invert(100%) brightness(130%);
`
const Powered = Styled.div`
  margin-top: ${rhythm(1)};
  font-size: ${rhythm(1 / 3)};
  font-weight: 200;
  a {
    color: ${colors.green};
    font-weight: 600;
    text-decoration: none !important;
  }
`

const Footer = () => {
  return (
    <Container>
      <Content>
        <Box>
          <Logo
            src={logoSVG}
            alt="Desarrollo de Aplicaciones Tenerife FeedYourWeb"
          />
        </Box>
        <Box>
          <Item>
            <ItemLink to={`/desarrollo-aplicaciones`}>Desarrollo de aplicaciones</ItemLink>
          </Item>
          <Item>
            <ItemLink to={`/paginas-web-posicionamiento-publicidad`}>Páginas web, posicionamiento, publicidad</ItemLink>
          </Item>
          <Item>
            <ItemLink to={`/portfolio`}>Portfolio</ItemLink>
          </Item>
          <Item>
            <ItemLink to={`/blog`}>Blog</ItemLink>
          </Item>
          <Item>
            <ItemLink to={`/quienes-somos`}>Sobre FeedYourWeb</ItemLink>
          </Item>
          <Item>
            <ItemLink to={`/terminos-y-condiciones`}>
              Términos y condiciones
            </ItemLink>
          </Item>
          <Item>
            <ItemLink to={`/politica-de-privacidad`}>
              Política de privacidad
            </ItemLink>
          </Item>
          <Item>
            <ItemA
              className="emailContactLink"
              rel="noreferrer"
              target="_blank"
              href={`mailto:jakubmz@gmail.com?subject=Presupuesto FeedYourWeb&body=Nombre y teléfono: `}>
              Contacto
            </ItemA>
          </Item>
          <ContactBar>
            <ContactItem
              className="emailContactLink"
              rel="noreferrer"
              target="_blank"
              href={`mailto:jakubmz@gmail.com?subject=Presupuesto FeedYourWeb&body=Nombre y teléfono: `}>
              <Icon src={emailSVG} alt="Email FeedYourWeb"
              />
            </ContactItem>
            <ContactItem
              className="whatsappContactLink"
              rel="noreferrer"
              target="_blank"
              href={`https://wa.me/34619786564`}>
              <Icon src={whatsappSVG} alt="Whatsapp FeedYourWeb"
              />
            </ContactItem>
            <ContactItem
              // onClick={trackEvent('twitter link', 'Twitter link click')}
              className="twitterContactLink"
              rel="noreferrer"
              target="_blank"
              href={`https://twitter.com/jakubmz`}>
              <Icon src={twitterSVG} alt="Twitter"/>
            </ContactItem>
          </ContactBar>
        </Box>
      </Content>
      <Powered>
        <strong>© {new Date().getFullYear()}, FeedYourWeb </strong> - powered by {` `}
        <a target="_blank" rel="noreferrer" href="https://www.gatsbyjs.org">GatsbyJS</a> + {` `}
        <a target="_blank" rel="noreferrer" href="https://www.netlify.com/">Netlify</a> = 🚀
      </Powered>
    </Container>
  )
}

export default Footer
