import React from "react"

import Styled from "styled-components"

import { rhythm } from "../utils/typography"

import { colors } from  "../utils/colors"

const themes = {
  white: {
    color: colors.darkGray,
    background: "#fff",
  },
  red: {
    color: "#fff",
    background: colors.red,
  },
  green: {
    color: colors.darkGray,
    background: "rgba(150, 180, 170, 1)",
  },
  blueGreen: {
    color: "#dfe",
    background: "linear-gradient(to bottom right, #1385C2, #83A751)",
  },
  blueGreen2: {
    color: "#fff",
    background: "linear-gradient(90deg, #00C9FF 0%, #92FE9D 100%)",
  },
  aqua: {
    color: "#fff",
    background:
      "linear-gradient(180deg, rgba(161, 161, 161, 0.01) 0%, rgba(161, 161, 161, 0.01) 13%,rgba(148, 148, 148, 0.01) 13%, rgba(148, 148, 148, 0.01) 100%),linear-gradient(334deg, rgba(3, 3, 3, 0.09) 0%, rgba(3, 3, 3, 0.09) 45%,rgba(38, 38, 38, 0.09) 45%, rgba(38, 38, 38, 0.09) 100%),linear-gradient(89deg, rgba(25, 25, 25, 0.01) 0%, rgba(25, 25, 25, 0.01) 49%,rgba(244, 244, 244, 0.01) 49%, rgba(244, 244, 244, 0.01) 100%),linear-gradient(350deg, rgba(191, 191, 191, 0.1) 0%, rgba(191, 191, 191, 0.1) 77%,rgba(42, 42, 42, 0.1) 77%, rgba(42, 42, 42, 0.1) 100%),linear-gradient(153deg, rgba(156, 156, 156, 0.04) 0%, rgba(156, 156, 156, 0.04) 56%,rgba(148, 148, 148, 0.04) 56%, rgba(148, 148, 148, 0.04) 100%),linear-gradient(223deg, rgba(135, 135, 135, 0.09) 0%, rgba(135, 135, 135, 0.09) 47%,rgba(9, 9, 9, 0.09) 47%, rgba(9, 9, 9, 0.09) 100%),linear-gradient(175deg, rgba(4, 4, 4, 0.08) 0%, rgba(4, 4, 4, 0.08) 60%,rgba(217, 217, 217, 0.08) 60%, rgba(217, 217, 217, 0.08) 100%),linear-gradient(283deg, rgba(36, 36, 36, 0.05) 0%, rgba(36, 36, 36, 0.05) 13%,rgba(156, 156, 156, 0.05) 13%, rgba(156, 156, 156, 0.05) 100%),linear-gradient(358deg, rgba(137, 137, 137, 0.04) 0%, rgba(137, 137, 137, 0.04) 45%,rgba(160, 160, 160, 0.04) 45%, rgba(160, 160, 160, 0.04) 100%),linear-gradient(90deg, hsl(185,66%,58%),hsl(185,66%,58%))",
  },
  redOrange: {
    color: "#fff",
    background: "linear-gradient(90deg, #d53369 0%, #daae51 100%)",
  },
  blue: {
    color: "#6ecdd5",
    background: "#44a8b9",
  },
  blueBlue: {
    color: "#fff",
    background: "linear-gradient(90deg, #1CB5E0 0%, #000851 100%)",
  },
  gray: {
    color: "#000",
    background: "#f0f0f0",
  },
  grayDark: {
    color: "#fff",
    background: "#333",
  },
  brown: {
    color: "#FFf1cF",
    background: "#DC8D53",
  },
  violet: {
    color: "#fff",
    background:
      "linear-gradient(0deg, rgba(243, 243, 243, 0.05) 0%, rgba(243, 243, 243, 0.05) 90%,rgba(162, 162, 162, 0.05) 90%, rgba(162, 162, 162, 0.05) 100%),linear-gradient(45deg, rgba(218, 218, 218, 0.03) 0%, rgba(218, 218, 218, 0.03) 44%,rgba(12, 12, 12, 0.03) 44%, rgba(12, 12, 12, 0.03) 100%),linear-gradient(135deg, rgba(50, 50, 50, 0.07) 0%, rgba(50, 50, 50, 0.07) 48%,rgba(149, 149, 149, 0.07) 48%, rgba(149, 149, 149, 0.07) 100%),linear-gradient(0deg, rgba(158, 158, 158, 0.1) 0%, rgba(158, 158, 158, 0.1) 48%,rgba(77, 77, 77, 0.1) 48%, rgba(77, 77, 77, 0.1) 100%),linear-gradient(135deg, rgba(184, 184, 184, 0.04) 0%, rgba(184, 184, 184, 0.04) 74%,rgba(20, 20, 20, 0.04) 74%, rgba(20, 20, 20, 0.04) 100%),linear-gradient(45deg, rgba(119, 119, 119, 0.04) 0%, rgba(119, 119, 119, 0.04) 42%,rgba(194, 194, 194, 0.04) 42%, rgba(194, 194, 194, 0.04) 100%),linear-gradient(135deg, rgba(14, 14, 14, 0.04) 0%, rgba(14, 14, 14, 0.04) 55%,rgba(84, 84, 84, 0.04) 55%, rgba(84, 84, 84, 0.04) 100%),linear-gradient(0deg, rgba(4, 4, 4, 0.1) 0%, rgba(4, 4, 4, 0.1) 30%,rgba(95, 95, 95, 0.1) 30%, rgba(95, 95, 95, 0.1) 100%),linear-gradient(135deg, rgba(83, 83, 83, 0.05) 0%, rgba(83, 83, 83, 0.05) 47%,rgba(80, 80, 80, 0.05) 47%, rgba(80, 80, 80, 0.05) 100%),linear-gradient(90deg, hsl(274,69%,68%),hsl(274,69%,68%))",
  },
}

const Wrapper = Styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${props => props.background};
  color: ${props => props.color};
  clip-path: ${props => props.angle};
`
const Content = Styled.section`
  max-width: 100%;
  width: ${rhythm(30)};
  margin: auto;
  padding: ${rhythm(1)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ContentBlock = ({ children, theme, angle }) => {
  theme = theme && themes[theme] ? themes[theme] : themes['white']
  angle = angle ? 'polygon(0 0, 100% 0, 100% 100%, 0 95%);' : false
  const color = theme.color;
  const background = theme.background;

  return (
    <Wrapper
      color={color}
      background={background}
      angle={angle}>
      <Content>{children}</Content>
    </Wrapper>
  )
}

export default ContentBlock
