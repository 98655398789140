import { useEffect } from 'react';

const useScript = (url, isAsync, js, appendTo) => {
  appendTo = appendTo ? appendTo : 'body'

  useEffect(() => {
    const script = document.createElement('script');
    if (url) {
      script.src = url
    }
    if (isAsync) {
      script.async = true;
    }
    if (js) {
      script.innerHTML = js;
    }

    console.debug('useScript appending script', script);
    document[appendTo].appendChild(script);

    return () => {
      console.debug('useScript removing script', script);
      document[appendTo].removeChild(script);
    }
  }, [url, isAsync, js, appendTo]);
};

export default useScript;