/**
 * Menu component
 */
import React from "react"

import { Link, useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import Styled from "styled-components"

import { rhythm } from "../utils/typography"

import Bio from '../components/bio';


const MenuBar = Styled.nav`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
`
const LogoLink = Styled(Link)`
  box-shadow: none;
  color: transparent;
`
const LogoImage = Styled(Image)`
  width: 80px;
  height: auto;
  margin: ${rhythm(1 / 2)} 0 0 ${rhythm(1 / 2)};
  box-shadow: none !important;
  color: transparent;
`
// const MenuList = Styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-evenly;
//   align-items: flex-end;
//   padding: 0 ${rhythm(1)};
//   max-width: ${rhythm(30)};
// `
// const MenuItem = Styled(Link)`
//   align-self: center;
//   margin: 0 ${rhythm(1 / 2)};
//   font-size: ${rhythm(1 / 2)};
//   box-shadow: none;
//   text-decoration: none;
//   color: #000;
// `
const HeaderBio = Styled.div`
  padding: ${rhythm(1 / 1.5)} ${rhythm(1 / 2)} ${rhythm(1 / 1.5)} ${rhythm(1 / 1.5)};
`

const Header = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      logo: file(absolutePath: { regex: "/.*-FEEDyourWEB-logo.png/" }) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)


  return (
    <MenuBar>
      <LogoLink to={`/`}>
        <LogoImage
          fixed={data.logo.childImageSharp.fixed}
          alt={"Logo"}
          style={{ width: 80, height: 80 }}
          imgStyle={{ width: 80, height: 80 }}
        />
      </LogoLink>
      {/* <MenuList> */}
      {/*   <MenuItem to={`/desarrollo-aplicaciones`}>Aplicaciones</MenuItem> */}
      {/*   <MenuItem to={`/paginas-web-posicionamiento-publicidad`}> */}
      {/*     Páginas Web + Marketing */}
      {/*   </MenuItem> */}
      {/*   <MenuItem to={`/portfolio`}>Portfolio</MenuItem> */}
      {/* </MenuList> */}
        <HeaderBio>
          <Bio header />
        </HeaderBio>
    </MenuBar>
  )
}

export default Header
