import React from 'react'

import useScript from '../hooks/useScript'

import { gtagJs, clickEventsJs } from '../components/googleAnalyticsEvents'
// import { clickEventsJs } from '../components/googleAnalyticsEvents'

const GoogleAnalytics = () => {
  useScript('https://www.googletagmanager.com/gtag/js?id=UA-110014271-1', true, false, 'body')
  useScript(false, false, gtagJs, 'body')
  useScript(false, false, clickEventsJs, 'body')
  return (<></>)
}

export default GoogleAnalytics