/*
 <head>
   <script async src="https://www.googletagmanager.com/gtag/js?id=UA-110014271-1"></script>
   <script>
     window.dataLayer = window.dataLayer || [];
     function gtag(){dataLayer.push(arguments);}
     gtag('js', new Date());
     gtag('config', 'UA-110014271-1');
   </script>
 </head>

 // TODO TODO TODO add click tracking
 // TODO TODO TODO add class on buttons/links (whatsappContactLink|emailContactLink|phoneContactLink)

   <script>
   // * Google Analytics link-click tracking events
   var phoneLinks = document.getElementsByClassName('phoneContactLink');
   for(var i=0; i < phoneLinks.length; i++) {
     (function(index) {
       phoneLinks[index].addEventListener("click", function() {
         console.log('###### Phone Contact Link clicked! index, pi ', index, phoneLinks[index]);
         __gaTracker('send', 'event', 'Contact Link', 'Phone Contact Link Click', 'phone call');
       })
     })(i);
   }
   var emailLinks = document.getElementsByClassName('emailContactLink');
   for(var i=0; i < emailLinks.length; i++) {
     (function(index) {
       emailLinks[index].addEventListener("click", function() {
         console.log('###### Email Contact Link clicked! index, pi ', index, emailLinks[index]);
         __gaTracker('send', 'event', 'Contact Link', 'Email Contact Link Click', 'email write');
       })
     })(i);
   }
   var whatsappLinks = document.getElementsByClassName('whatsappContactLink');
   for(var i=0; i < whatsappLinks.length; i++) {
     (function(index) {
       whatsappLinks[index].addEventListener("click", function() {
         console.log('###### Whatsapp Contact Link clicked! index, pi ', index, whatsappLinks[index]);
         __gaTracker('send', 'event', 'Contact Link', 'Whatsapp Contact Link Click', 'whatsapp chat');
       })
     })(i);
   }
 </script>
 */

export const gtagJs = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'UA-110014271-1');
`

export const clickEventsJs = `
  // * Google Analytics link-click tracking events
  var emailLinks = document.getElementsByClassName('emailContactLink');
  for(var i=0; i < emailLinks.length; i++) {
    (function(index) {
      emailLinks[index].addEventListener("click", function() {
        console.log('# Email Contact Link clicked! index, pi ', index, emailLinks[index]);
        // __gaTracker('send', 'event', 'Contact Link', 'Email Contact Link Click', 'email write');
        gtag('event', 'Contact Link', { 'event_category' : 'Email Link Click', 'event_label' : 'email view' });
      })
    })(i);
  }

  var whatsappLinks = document.getElementsByClassName('whatsappContactLink');
  for(var i=0; i < whatsappLinks.length; i++) {
    (function(index) {
      whatsappLinks[index].addEventListener("click", function() {
        console.log('# Whatsapp Contact Link clicked! index, pi ', index, whatsappLinks[index]);
        // __gaTracker('send', 'event', 'Contact Link', 'Whatsapp Contact Link Click', 'whatsapp chat');
        gtag('event', 'Contact Link', { 'event_category' : 'Whatsapp Link Click', 'event_label' : 'whatsapp view' });
      })
    })(i);
  }

  var twitterLinks = document.getElementsByClassName('twitterContactLink');
  for(var i=0; i < twitterLinks.length; i++) {
    (function(index) {
      twitterLinks[index].addEventListener("click", function() {
        console.log('# Twitter Link clicked! index, pi ', index, twitterLinks[index]);
        // __gaTracker('send', 'event', 'Contact Link', 'Twitter Link Click', 'twitter view');
        gtag('event', 'Contact Link', { 'event_category' : 'Twitter Link Click', 'event_label' : 'twitter view' });
      })
    })(i);
  }

`