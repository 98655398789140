import React, { useState, useEffect } from "react"

import { Link } from "gatsby"

import Styled from "styled-components"

import { rhythm } from "../utils/typography"
import { colors } from "../utils/colors"


const Overlay = Styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${rhythm(0.2)} ${rhythm(0.35)};
  background: #fff;
  border-radius: 5px 0 0 0;
  box-shadow: 0px 0px 2px 2px rgba(100,100,100, 0.2);
  transition: all 0.2s ease-in;
`
const Title = Styled.span`
  color: ${colors.darkGray};
  font-size: ${rhythm(0.35)};
  margin-right: ${rhythm(0.1)};
`
const MyLink = Styled(Link)`
  margin: 0 ${rhythm(0.1)};
`
const CloseIcon = Styled.span`
  margin: auto 0 auto ${rhythm(0.1)};
  padding: ${rhythm(0.135)} ${rhythm(0.21)} ${rhythm(0.12)} ${rhythm(0.2)};
  font-size: ${rhythm(0.3)};
  font-weight: 100;
  border-radius: 30px;
  background-color: ${colors.midGray};
  color: white;
  transition: all 0.2s ease-in;

  &:hover {
    background-color: black;
  }
`


const CookiesPopup = () => {
  const [ hideCookiesPopup, setHideCookiesPopup] = useState(false)
  useEffect(() => {
    setHideCookiesPopup(localStorage.getItem('hideCookiesPopup'))
  }, [])

  const onClick = () => {
    setHideCookiesPopup(true)
    localStorage.setItem('hideCookiesPopup', true)
  }

  return (
    <>
    { !hideCookiesPopup ?
    <Overlay>
      <Title>
        FeedYourWeb utiliza cookies.
        <MyLink to="/politica-de-privacidad">Política de privacidad</MyLink>
        <MyLink to="/terminos-y-condiciones">Términos y condiciones</MyLink>
        <CloseIcon onClick={onClick}>𝖷</CloseIcon>
      </Title>
      </Overlay>
    : null }
    </>
  )
}

export default CookiesPopup