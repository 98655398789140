/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import Styled from "styled-components"

import { rhythm } from "../utils/typography"
import { colors } from "../utils/colors"

const Wrapper = Styled.section`
  display: flex;
  padding: ${rhythm(1)} ${rhythm(1 / 2)} 0 ${rhythm(1 / 2)};
`
const Description = Styled.p`
  font-size: ${rhythm(0.45)};
  color: ${colors.midGray};
`
const TwitterLink = Styled.a`
  color: ${colors.midGray};
  transition: all 0.2s ease-in;
  &:hover {
    font-weight: bold;
    transform: scale(1.03);
  }
`
const Avatar = Styled(Image)`
  margin-right: ${rhythm(1 / 2)};
  margin-bottom: 0;
  min-width: 60px;
  border-radius: 50%;
`
const WrapperSm = Styled.section`
  display: flex;
  padding: 0;
  margin: 0;
`
const AvatarSm = Styled(Image)`
  margin-right: ${rhythm(1 / 4)};
  margin-bottom: 0;
  border-radius: 50%;
`

const Bio = ({ small, header }) => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/.*jakubmz-avatar-pic-lg-mono3.jpg/" }) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            twitter
          }
        }
      }
    }
  `)
  const { author, social } = data.site.siteMetadata

  if (small) {
    return (
      <WrapperSm>
        <AvatarSm
          fixed={data.avatar.childImageSharp.fixed}
          alt={author}
          style={{ width: 30, height: 30 }}
          imgStyle={{ width: 30, height: 30 }}
        />
        <strong>{author}</strong>
      </WrapperSm>
    )
  }

  if (header) {
    return (
      <WrapperSm>
        <AvatarSm
          fixed={data.avatar.childImageSharp.fixed}
          alt={author}
          style={{ width: 70, height: 70 }}
          imgStyle={{ width: 70, height: 70 }}
        />
      </WrapperSm>
    )
  }

  return (
    <Wrapper>
      <Avatar
        fixed={data.avatar.childImageSharp.fixed}
        alt={author}
        style={{ width: 60, height: 60 }}
        imgStyle={{ width: 60, height: 60 }}
      />
      <Description>
        <strong>{author}</strong> vive y trabaja remotamente desde Tenerife
        (Islas Canarias) en desarrollo de aplicaciones y páginas web. En ratos libres practica
        <span role="img" aria-label="meditation">🧘🏼‍♂️</span>
        <span role="img" aria-label="surf">🏄🏼‍♂️</span>
        <span role="img" aria-label="ocean">🌊</span>
        {` `} Puedes seguirle en {` `}
        {/* Written by <strong>{author}</strong> who lives and works in Tenerife / Spain */}
        {/* and creates awesome things line by line. */}
        <TwitterLink
          href={`https://twitter.com/${social.twitter}`}
          target="_blank"
        >
          Twitter
        </TwitterLink>
      </Description>
    </Wrapper>
  )
}

export default Bio
