/*
 * Theme colors
 */

export const colors = {
  darkGray: '#222',
  midDarkGray: '#444',
  midGray: '#666',
  gray: '#888',
  lightGray: '#aaa',
  lighterGray: '#ccc',
  lightestGray: '#eee',
  green: 'rgba(150,180,170,1)',
  red: 'rgba(200,100,100,1)'
};