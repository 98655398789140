import React from "react"

// import { rhythm } from "../utils/typography"
// import slugify from "slugify"

import Header from "../components/header"
import Footer from "../components/footer"
import CookiesPopup from "../components/cookiesPopup"
import GoogleAnalytics from "../components/googleAnalytics"

import "./utils.css"

class Layout extends React.Component {
  render() {
    const { children } = this.props
    // const { location, title, children } = this.props
    // const rootPath = `${__PATH_PREFIX__}/`
    // if (location.pathname === rootPath) {
    return (

      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: `100%`,
          // maxWidth: rhythm(24),
          // padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <GoogleAnalytics />
        <Header />
        <main>{children}</main>
        <Footer />
        <CookiesPopup />
      </div>
    )
  }
}

export default Layout;
